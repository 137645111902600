import React from 'react';
import { graphql } from 'gatsby';
import { camelizeKeys } from 'humps';
import { shape } from 'prop-types';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import DynamicZone from '../../components/DynamicZone';
import Seo from '../../components/seo';

const NotFoundPage = ({ data }) => {
  const { notFoundPage } = camelizeKeys(data.strapi);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;

  const seo = notFoundPage && notFoundPage.seo;

  return (
    <Layout
      containerClassName="home-page-layout"
      headerData={headerData}
      footerData={footerData}
      signUpData={signUpData}
    >
      {seo && (
        <Seo
          title={seo?.title}
          description={seo?.description}
        />
      )}
      {notFoundPage && !isEmpty(notFoundPage.content) && (
        <div className="not-found-page-wraper">
          <DynamicZone
            components={notFoundPage.content}
          />
        </div>
      )}
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: shape({
    strapi: shape(),
  }),
};

NotFoundPage.defaultProps = {
  data: {
    strapi: {},
  },
};

export default NotFoundPage;

export const notFoundQuery = graphql`
  query getNotFoundPage {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      notFoundPage {
        seo {
          description
          id
          title
        }
        content {
          ... on STRAPI_ComponentSectionsNotFoundPageContent {
            id
            __typename
            backgroundText
            description
            title
            button {
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                label
                path
                openInNewWindow
              }
            }
          }
        }
      }
    }
  }
`;
